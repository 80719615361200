import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Index = () => import('/src/pages/Index.vue')
const Home = () => import('/src/pages/Home.vue')
const Survey = () => import('/src/pages/Survey.vue')
const Finish = () => import('/src/pages/Finish.vue')

const router = new VueRouter({
  routes: [
    { path: '/', name: 'index', component: Index },
    { path: '/home', name: 'home', component: Home },
    { path: '/survey', name: 'survey', component: Survey },
    { path: '/finish', name: 'finish', component: Finish },
  ],
})

export default router
